import "./card.css";
import {
  TrendingUp,
  PeopleOutlineOutlined,
  SupportAgent,
  HomeRepairService,
  BusinessCenter,
  RoomService,
} from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import Toptag from "../../components/topTag/Toptag";
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
// import APIFunction from '../../ApiFunction'
import Loader from "../loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setCount } from "../../slice/count";
import { getRequest } from "../../ApiFunction";
import API from "../../Api";

export default function Card() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.userinfo.user);

  const usersCount = useSelector(state => state.count.counts.userCount);
  const providersCount = useSelector(state => state.count.counts.providerCount);
  const servicesCount = useSelector(state => state.count.counts.serviceCount);
  const supportCount = useSelector(state => state.count.counts.supportCount);

  const getCounts = async () => {
    try {
      const result = await getRequest(`${API.GET_COUNTS}`);
      if (!result.data.status) {
        if (result.data.code === 401) {
          localStorage.clear("admintoken");
          sessionStorage.clear("admintoken");
          navigate("/");
          toast.info("Session expired");
        }
        toast.error(result.data.message);
      } else {
        dispatch(setCount(result.data.data));
        localStorage.setItem("count", JSON.stringify(result.data.data));
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Toptag />
          <div className="container">
            <div className="cardWrapper">
              <NavLink to="/users" className="link">
                <div
                  className="cardContainer"
                  style={{ backgroundColor: "#43aa8b" }}
                >
                  <div className="cardTopLogo">
                    <PeopleOutlineOutlined className="cardLogo" />
                  </div>
                  <span className="cardTitle">User's</span>
                  <span className="userCount">
                    <TrendingUp className="trendingLogo" />
                    {usersCount}
                  </span>
                </div>
              </NavLink>
            </div>
          </div>
        </>
      )}
    </>
  );
}
