import { AccountCircle, Call, AlternateEmail, KeyboardArrowRight, LocalOffer, CalendarMonth, Person, AccessTime } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Toptag from '../../components/topTag/Toptag'
import Loader from '../../components/loader/Loader'
import API from '../../Api'
import { BackPaper, BookingText, DetailData, DetailLabel, LabelBooking, TimePrice } from '../../components/Styles'
import moment from 'moment'
import { getRequest } from '../../ApiFunction'
import toast from 'react-hot-toast'
import { Chip } from '@mui/material'



const BookingDetails = () => {
  const router = useParams()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [obj, setObj] = useState({})





  const getAll = async () => {
    const result = await getRequest(`${API.BOOKING_DETAILS}?id=${router.id}`);
    if (!result.data.status) {
      if (result.data.code === 203) {
        localStorage.remove('adminToken');
        localStorage.clear()
        navigate('/')
        toast.info("Session expired!")
      }
      toast.error(result.data.message)
    } else {
      setObj(result.data.data)
      await delay(1000);
      setIsLoading(false)
    }
  }




  useEffect(() => {
    setIsLoading(true)
    getAll()
  }, [])

  return (
    <>
      {isLoading ?
        <Loader />
        :
        <>
          <Toptag />
          <BackPaper style={{ padding: "20px" }}>
            <div className='row service-style'>
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                <div className='d-flex justify-content-start flex-column align-items-start gap-4'>
                  <div className='mt-3 w-100'>
                    <div className='d-flex justify-content-between align-items-start mt-1'>
                      <div className='heading'>Services Details</div>
                      <Chip icon={<LocalOffer />} label="Premium" />
                    </div>
                    <div className='service-overflow'>
                      {obj.selected_service && obj.selected_service.map((element, index) => (
                        <>
                          <div className='d-flex justify-content-between align-items-start mt-1'>
                            <p>service name:</p>
                            <div className='name'>{element.service_name}</div>
                          </div>
                          <div className='d-flex justify-content-between align-items-start mt-1'>
                            <p>service price:</p>
                            <div className='name'>${element.service_price}</div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                <div className='d-flex justify-content-start flex-column align-items-start gap-4'>
                  <div className='mt-3 w-100'>
                    <div className='d-flex justify-content-between align-items-start mt-1'>
                      <div className='heading'>Booking Details</div>
                      <Chip icon={<CalendarMonth />} label="Scheduled" />
                    </div>
                    <div className='mt-3'>
                      <div className='d-flex justify-content-between align-items-start'>
                        <p>Booked By(Name):</p>
                        <div className='name'>{obj.booked_by.name}</div>
                      </div>
                      <div className='d-flex justify-content-between align-items-start'>
                        <p>Booked By(email):</p>
                        <div className='name'>{obj.booked_by.email}| {obj.booked_by.countryCode + '' + obj.booked_by.phone_number}</div>
                      </div>
                      <div className='d-flex justify-content-between align-items-start'>
                        <p>Total amount paid:</p>
                        <div className='name'>${obj.total_price}</div>
                      </div>
                      <div className='d-flex justify-content-between align-items-start'>
                        <p>Status</p>
                        <div className='name'>{obj.isCompleted ? 'Completed' : 'pending'}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-3 w-100'>
                <div className='d-flex justify-content-between align-items-start mt-1'>
                  <div className='heading'>Provider Details </div>
                  <Chip icon={<Person />} label="Owner" />
                </div>
                <div className='mt-3'>
                  <div className='d-flex justify-content-between align-items-start'>
                    <p>Provider name:</p>
                    <div className='name'>{obj.business_name}</div>
                  </div>
                  <div className='d-flex justify-content-between align-items-start'>
                    <p>Provider contact:</p>
                    <div className='name'>{obj.booked_by.business_email}| {obj.booked_by.countryCode + '' + obj.booked_by.phone_number}</div>
                  </div>
                </div>
              </div>
              <NavLink to={'/providers/' + obj.businessId}><div className='viewText'>view provider</div></NavLink>
              <div className='mt-3 w-100'>
                <div className='d-flex justify-content-between align-items-start mt-1'>
                  <div className='heading'>Booking Time</div>
                  <Chip icon={<AccessTime />} label="Scheduled" />
                </div>
                <div className='mt-3'>
                  <div className='d-flex justify-content-between align-items-start'>
                    <p>date and time:</p>
                    <div className='name'>{moment(obj.appointment_date).format('LLL')}</div>
                  </div>
                </div>
              </div>
            </div>
          </BackPaper>

        </>
      }

    </>
  )
}

export default BookingDetails