import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { postRequest } from '../../ApiFunction';
import API, { BASE_URL } from '../../Api';
import toast from 'react-hot-toast';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from 'react-router-dom';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const ImportData = ({ companies }) => {
    const [jsonData, setJsonData] = useState(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setJsonData(json);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const [error, setError] = useState(null)
    const [temp, setTemp] = useState([])
    const [data, setData] = useState([])

    const [obj, setObj] = useState({
        users: [],
        companyId: ""
    })

    useEffect(() => {
        (() => {
            let allowed = ['name', 'email', 'password']
            let err = false
            try {
                if (jsonData) {
                    jsonData.map(item => {
                        let keys = Object.keys(item);
                        for (let key of keys) {
                            if (!allowed.includes(key)) {
                                err = true;
                                setError("Extra keys found " + (keys.filter(i => !allowed.includes(i))).join(", ") + ". Please download the format and arrange the data.")
                                setData([])
                                return;
                            }
                        }
                    })
                    if (!err) {
                        setTemp(jsonData)
                    }
                    // let set = new Set();
                    // set.add()
                }
            } catch (err) {
                setError(err)
            }
        })()
    }, [jsonData])

    async function checkData(data) {
        try {
            const result = await postRequest(`${API.CHECK_DATA}`, data);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.remove('adminToken');
                    localStorage.clear()
                    toast.info("Session expired!")
                }
                toast.error(result.data.message)
            } else {
                // toast.success(result.data.message)
                setError(result.data.duplicate ? "Duplicate entries found" : result.data.error ? "Some emails are already exists" : null)
                if (result.data.error && !result.data.error) {
                    setData([])
                } else {
                    setData(result.data.data)
                }
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    const nav = useNavigate()

    useEffect(() => {
        if (temp.length > 0) {
            let body = {
                data: temp,
                list: temp.map(item => item.email)
            }
            checkData(body)
        }
    }, [temp])

    function onChange(e) {
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        })
    }

    function download() {
        window.open("/images/format.xlsx", '_blank');
    }

    async function addMultipleUsers() {
        try {
            const result = await postRequest(`${API.ADD_MULTIPLE_USERS}`, { ...obj, users: data });
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.remove('adminToken');
                    localStorage.clear()
                    toast.info("Session expired!")
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                nav(-1)
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    return (
        <div>
            <div className='d-flex justify-content-between'>
                <input type="file" className='mb-3' accept=".xlsx, .xls" onChange={handleFileUpload} />
                <Button variant='outlined' onClick={() => download()}>Download Formate</Button>
            </div>
            {data.length > 0 && (
                <table class="table table-striped table-bordered text-center">
                    <thead>
                        <tr>
                            <th scope="col">Sr</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Password</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.password}</td>
                            <td>{!item.exists ? <DoneIcon /> : <CloseIcon />}{item.exists && <>(Email already exists)</>}</td>
                        </tr>)}
                    </tbody>
                </table>
            )}
            <div className='row form-handler'>
                {!error && data.length > 0 && <div className='col-md-6 mb-3'>
                    <FormControl className='w-100'>
                        <InputLabel id="demo-simple-select-label">Please select a company</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={obj.companyId}
                            name="companyId"
                            label="Company"
                            onChange={onChange}
                        >
                            {companies.map(item => <MenuItem value={item._id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>}
                <div className='col-md-6 mb-3'>
                    {!error && data.length > 0 && <Button variant='contained' className='tripiBtn ' onClick={() => addMultipleUsers()}>Submit Data</Button>}
                </div>
                {error && <p className='text-danger'>{error}</p>}
            </div>
        </div>
    );
};

export default ImportData;
