import { Block, Close, Delete, RemoveRedEye, Search } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import { getRequest, postRequest } from "../../ApiFunction"
import {  useSelector } from 'react-redux'
import API, { BASE_URL } from '../../Api'
import { BackPaper, DetailText, NavPaper } from '../../components/Styles'
import moment from 'moment'
import codes from 'country-calling-code';
import toast from 'react-hot-toast'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Booking = () => {
  const bookingCount = useSelector(state => state.count.counts.bookingCount);
  const [isLoading, setIsLoading] = useState(true)
  const [certificate, setCertificate] = useState(false)
  const error = useSelector(state => state.count.error);
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [row, setRow] = useState('10');
  const [skip, setSkip] = useState('0')
  const [key, setKey] = useState("")
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [id, setId] = useState()
  const [status, setStatus] = useState()
  const [obj, setObj] = useState({})


  if (error === 203) {
    localStorage.clear();
    navigate('/')
    toast.info("Session expired")
  }

  const handleClose = () => {
    setCertificate(false)
  }



  const handleBlockOpen = (id, status) => {
    setId(id)
    setStatus(status)
    setOpen(true);
  };

  const handleBlock = () => {
    setOpen(false);
  };

  const handleRemoveOpen = (id) => {
    setId(id)
    setRemOpen(true);
  };

  const handleRemove = () => {
    setRemOpen(false);
  };

  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery)
    setSkip(0);
  }


  const removeUser = async (id) => {
    try {
      const result = await postRequest(`${API.DELETE_REQUEST}`, { id: id })
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.remove('adminToken');
          localStorage.clear()
          navigate('/')
          toast.info("Session expired!")
        }
        toast.error(result.data.message)
      } else {
        toast.success(result.data.message)
        getAll()
        setRemOpen(false)
      }
    } catch (error) {
      console.log(error.message)
    }
  }





  const getAll = async () => {
    const result = await getRequest(`${API.GET_BOOKING}?value=${key}&row=${row}&skip=${skip}`);
    if (!result.data.status) {
      if (result.data.code === 203) {
        localStorage.remove('adminToken');
        toast.info("Session expired!")
        localStorage.clear()
        navigate('/')
      }
      toast.error(result.data.message)
    } else {
      setAll(result.data.data)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getAll()
  }, [key, row, skip])


  const handleChange = (e) => {
    if (e.target.value === bookingCount) {
      setSkip(0)
      setRow(bookingCount)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }


  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Doc: {bookingCount}</div></Grid>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={row}
                        onChange={handleChange}
                      >
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <div className='searchBar'>
                      <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                      <Search className='searchIcon' />
                    </div>
                  </Grid>
                </Grid>

              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 && key ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">Booking not found related to your search!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell align='center'>Booked Since</TableCell>
                        <TableCell align='center' >Booked by User</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell align='center'>Services Count</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align='center'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {all.map((element, index) => {
                        return (<>
                          <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell align='center'>{moment(element.createdAt).format('LLL')}</TableCell>
                            <TableCell align='center'>{element.booked_by_name}</TableCell>
                            <TableCell>
                            <NavLink to={'/users/' + element.userId}>
                              <div className="viewText">{element.booked_by_email}</div>
                            </NavLink>
                              </TableCell>
                            <TableCell align='center'>{element.services.length}</TableCell>
                            <TableCell>
                              {element.isCompleted ?
                                <span class="badge bg-success">Completed</span>
                                : element.isAccepted ?
                                  <span class="badge bg-warning text-dark">Pending</span>
                                  : element.isRejected? <span class="badge bg-danger">Rejected</span>:
                                  <span class="badge bg-light text-dark">Progress..</span>
                                  }
                            </TableCell>
                            <TableCell>
                              <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="view details" >
                                    <NavLink to={'/bookings/' + element._id} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>
                                {!element.isCertified ? null :
                                  <Grid item>
                                    <Tooltip title={!element.isBlocked ? 'block user' : 'unblock user'} >
                                      {element.isBlocked ?
                                        <Block className="blockIcon" style={{ color: "green" }} onClick={() => { handleBlockOpen(element._id, element.isBlocked) }} />
                                        :
                                        <Block className="blockIcon" style={{ color: "red" }} onClick={() => { handleBlockOpen(element._id, element.isBlocked) }} />
                                      }
                                    </Tooltip>
                                  </Grid>}
                                <Grid item>
                                  <Tooltip title="Delete user from app" >
                                    <Delete className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }


              {/* dilogue for delete user */}
              <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure you want to delete this request from the app? The business provider will not see this request, and the user will not be informed that you deleted their request.?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleRemove}>No</Button>
                  <Button onClick={() => { removeUser(id) }} >Yes</Button>
                </DialogActions>
              </Dialog>

            </BackPaper>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(bookingCount / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>
          </>

        )}
    </>
  )
}

export default Booking









