import React from "react";
import "./sidebar.css";

import { NavLink } from "react-router-dom";

import {
  PeopleOutline,
  ContentPasteOutlined,
  Home,
  CardMembership,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const user = useSelector((state) => state.userinfo.user);

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <NavLink to="/dashboard">
            <div className="sidebarSelect">
              <div className="sidebarSelectHead">
                <Home className="icon" />
                <span>DashBoard</span>
              </div>
            </div>
          </NavLink>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <PeopleOutline className="icon" />
              <span>User Management</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/users">
              <div>Users</div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <PeopleOutline className="icon" />
              <span>Company Management</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/companies">
              <div>Companies</div>
            </NavLink>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/company-users">
              <div>Company Users</div>
            </NavLink>
          </div>
        </div>
        <hr />
        <div className="sidebarMenu">
          <div className="sidebarSelect">
            <NavLink to="/all-transactions">
              <div className="sidebarSelectHead">
                <CardMembership className="icon" />
                <span>Memberships & Plans</span>
              </div>
            </NavLink>
          </div>
        </div>
        <hr></hr>
        <div className="sidebarMenu">
          <div className="sidebarSelect">
            <div className="sidebarSelectHead">
              <ContentPasteOutlined className="icon" />
              <span>Content</span>
            </div>
          </div>
          <div className="sidebarSelectList">
            <NavLink to="/terms-condition-management">
              <div>Terms & Conditions</div>
            </NavLink>
            <NavLink to="/privacy-policy-management">
              <div>Privacy Policy</div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
