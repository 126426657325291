import React from 'react'
import Card from '../../components/cards/Card'

const Home = () => {
  return (
    <Card />
  )
}

export default Home
