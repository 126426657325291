import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../../ApiFunction'
import API from '../../Api'
import toast from 'react-hot-toast'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'

const ChangeCompany = ({ id = "", userId = "", onClose }) => {
    const [companies, setCompanies] = useState([])

    const [obj, setObj] = useState({
        companyId: ""
    })

    function onChange(e) {
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        })
    }

    async function submitDetails() {
        try {
            const { data } = await postRequest(API.UPDATE_USER_COMPANY, { ...obj, companyId: obj.companyId === "empty" ? "" : obj.companyId });
            if (data.status) {
                toast.success(data.message)
                // nav(-1)
                onClose()
            } else {
                toast.error(data.message)
            }
        } catch (err) {
            toast.error(err)
        }
    }

    const getCompanies = async () => {
        const result = await getRequest(`${API.COMPANY_LIST}`);
        if (!result.data.status) {
            if (result.data.code === 203) {
                localStorage.remove('adminToken');
                toast.info("Session expired!")
                localStorage.clear()
                // nav('/')
            }
            toast.error(result.data.message)
        } else {
            setCompanies([{ name: "No company", _id: "empty" }, ...result.data.data])
            // if (result.data.data.length > 0) {
            //     setObj({ companyId: id })
            // }
            //   setIsLoading(false)
        }
    }

    const [value, setValue] = useState('1');


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setObj({
            companyId: id === "" ? "empty" : id,
            userId: userId
        })
    }, [id, userId])

    useEffect(() => {
        getCompanies()
    }, [])


    return (
        <div className='row form-handler'>
            <div className='col-md-12 mb-3'>
                <FormControl >
                    <InputLabel id="demo-simple-select-label">Please select a company</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={obj.companyId}
                        name="companyId"
                        label="Company"
                        onChange={onChange}
                    >
                        {companies.map(item => <MenuItem value={item._id}>{item.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div className='col-md-6 mb-3'>
                <Button variant="outlined" style={{ color: "white !important" }} onClick={submitDetails}>Submit</Button>
            </div>
        </div>
    )
}

export default ChangeCompany