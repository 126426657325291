import React, { useEffect, useState } from 'react'
import { BackPaper } from '../../components/Styles'
import { getRequest, postRequest } from '../../ApiFunction'
import API from '../../Api'
import { useParams } from 'react-router-dom'


const UserChat = () => {
    const [obj, setObj] = useState({ message: '' })
    const router = useParams()
    const [userChat, setUserChat] = useState([])
    const [businessChat, setBusinessChat] = useState([])
    const [id, setId] = useState(localStorage.getItem('id'))
    const inputEvent = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value,
        })
    };



    const getAllChats = async () => {
        try {
            const result = await getRequest(`${API.GET_ALL_CHATS}?id=${router.id}`);
            setUserChat(result.data.data)
        } catch (err) {
            console.error(err.message);
        }
    };



    const chatStartUser = async () => {
        try {
            let data = {
                message: obj.message,
                id: router.id
            }
            const result = await postRequest(`${API.SEND_MESSAGE}`, data);
            getAllChats()
            setObj({message:''})
        } catch (err) {
            console.error(err.message);
        }
    };




    useEffect(() => {
        getAllChats()
    }, [router])


    return (
        <BackPaper>
            <div className='mt-4'></div>
            <div className='row mt-4'>
                <div className='col-6'>
                    <div className='chat-page'>
                        <div className='text-center w-100'>User side</div>
                        <div style={{ overflow: 'auto', height: "400px" }}>
                            {userChat && userChat.map((element, index) => (
                                <div className='chats' key={index + 1}>
                                    {!element.sendByUser ? <div>{element.message}</div>
                                        :
                                        <div className='d-flex justify-content-end w-100'>{element.message}</div>
                                    }
                                </div>
                            ))}
                        </div>
                        <input type='text' onChange={inputEvent} name="message" id="message" value={obj.message} />
                        <button onClick={chatStartUser}>send</button>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='chat-page'>
                        <div className='text-center w-100'>Business side</div>
                        <div style={{ overflow: 'auto', height: "400px" }}>
                            {businessChat && businessChat.map((element, index) => (
                                <div className='chats' key={index + 1}>
                                    {element.sendByUser ? <div>{element.message}</div>
                                        :
                                        <div className='d-flex justify-content-end w-100'>{element.message}</div>
                                    }
                                </div>
                            ))}
                        </div>
                        <input type='text' />
                        <button>send</button>
                    </div>
                </div>
            </div>
        </BackPaper>
    )
}

export default UserChat
