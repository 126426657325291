import "./login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest, setToken } from "../../ApiFunction";
import { setCount } from "../../slice/count";
import { useDispatch } from "react-redux";
import { setUser } from "../../slice/userInfo";
import API, { BASE_URL } from "../../Api";
import toast from "react-hot-toast";
import { Container } from "@mui/material";
import { LoginPaper } from "../../components/Styles";
import { LoginLogo } from "../../icons/icons";

export default function Login() {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [message, setMessage] = useState(false);
  const [style, setStyle] = useState("container1 left-panel-active");
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [view, setView] = useState(false);
  const [obj, setObj] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const handleView = () => {
    setView(!view);
  };

  const handleForgot = () => {
    setForgot(!forgot);
  };
  const inputEvent = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });
  };

  const getCounts = async () => {
    try {
      const result = await getRequest(`${API.GET_COUNTS}`);
      if (!result.data.status) {
        if (result.data.code === 401) {
          localStorage.clear("admintoken");
          sessionStorage.clear("admintoken");
          navigate("/admin");
          toast.info("Session expired");
        }
        toast.error(result.data.message);
      } else {
        dispatch(setCount(result.data.data));
        localStorage.setItem("count", JSON.stringify(result.data.data));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      setLoad(true);
      const result = await postRequest(`${API.LOGIN}`, obj);
      if (!result.data.status) {
        await delay(1000);
        toast.error(result.data.message);
        setLoad(false);
      } else {
        setToken(result.data.token);
        getCounts();
        localStorage.setItem("adminToken", result.data.token);
        localStorage.setItem("user", JSON.stringify(result.data.data));
        toast.success("Logged In successfully!");
        dispatch(setUser(result.data.data));
        await delay(1000);
        setLoad(false);
        navigate("/dashboard");
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const backLogin = () => {
    setMessage(false);
    setForgot(false);
  };

  const submitFormForgotPass = async (event) => {
    event.preventDefault();
    try {
      setLoad(true);
      const result = await postRequest(`${API.FORGOT_PASSWORD}`, obj);
      if (!result.data.status) {
        await delay(1000);
        toast.error(result.data.message);
        setLoad(false);
      } else {
        toast.success(result.data.message);
        setMessage(true);
        setLoad(false);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      <div className="loginBg">
        <Container maxWidth="xs">
          <LoginPaper data-aos="zoom-in">
            <form onSubmit={submitForm} method="post">
              <div className="login-page">
                <div className="w-100 text-center">
                  <LoginLogo />
                  <div className="fields">
                    <div className="logo mt-4" data-aos="fade-up">
                      Sign In to Administrator
                    </div>
                  </div>
                </div>
                {forgot ? (
                  <>
                    {message ? (
                      <div class="successfulMessage w-100 d-flex justify-content-center align-items-center gap-3 flex-column">
                        <h4>Email Sent Successfully</h4>
                        <p>
                          Please check your email and follow the instructions to
                          reset your password.
                        </p>
                        <span class="back-to-login" onClick={backLogin}>
                          Back to login
                        </span>
                      </div>
                    ) : (
                      <div className="fields" style={{ gap: "20px" }}>
                        <div className="logo mt-4" data-aos="fade-up">
                          Forgot Your Password?
                        </div>
                        <label>Your Email</label>
                        <input
                          required={true}
                          type="email"
                          id="email"
                          name="email"
                          onChange={inputEvent}
                          onKeyDown={handleKeyDown}
                        />
                        <div
                          className="forgotPassword w-100 text-center"
                          onClick={handleForgot}
                        >
                          Did you remembered your password <span>Sign In?</span>
                        </div>
                        <button
                          className="tripiBtn w-100"
                          disabled={load || obj.email === ""}
                          onClick={submitFormForgotPass}
                        >
                          {load ? "Verifying..." : "Continue"}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="fields">
                    <label>Email</label>
                    <input
                      required={true}
                      type="email"
                      id="email"
                      name="email"
                      onChange={inputEvent}
                      onKeyDown={handleKeyDown}
                    />
                    <label>Password</label>
                    <div className="w-100 view">
                      <input
                        required={true}
                        type={view ? "text" : "password"}
                        id="password"
                        name="password"
                        onChange={inputEvent}
                        onKeyDown={handleKeyDown}
                      />
                      <i
                        class={
                          !view ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                        }
                        onClick={handleView}
                      ></i>
                    </div>
                    <button className="tripiBtn w-100 mt-2" disabled={load}>
                      {load ? "authenticating..." : "Sign In"}
                    </button>
                  </div>
                )}
                {!forgot ? (
                  <div
                    className="forgotPassword w-100 text-center"
                    onClick={handleForgot}
                  >
                    <label>
                      Didn't remembered password <span>Forgot Password?</span>
                    </label>
                  </div>
                ) : null}
              </div>
            </form>
          </LoginPaper>
        </Container>
      </div>
    </>
  );
}
