import { Paper, Typography, styled } from "@mui/material";

export const BackPaper = styled(Paper)({
    height: "100vh",
    borderRadius: "0",
    padding: "10px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
})

export const LoginPaper = styled(Paper)({
    height: "auto",
    border: "1px solid rgba(114, 114, 114, 0.3)",
    width: "100%",
    backgroundColor: "#fff",
    padding: "30px",
    overflow: "auto",
    boxShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
})



export const NavPaper = styled(Paper)({
    padding: "7px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
})


export const DetailLabel = styled(Typography)({
    fontSize: "14px",
    color: "#000814",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    fontFamily: 'system-ui',
    fontWeight: "bold"
})

export const LabelBooking = styled(Typography)({
    fontSize: "25px",
    color: "#000000",
    textTransform: 'capitalize',
    fontWeight: "500",
    fontFamily: 'system-ui',
})

export const TimePrice = styled(Typography)({
    fontSize: "16px",
    color: "#000000",
    textTransform: 'capitalize',
    fontWeight: "500",
    fontFamily: 'system-ui',
})



export const DetailData = styled(Typography)({
    fontSize: "15px",
    color: "#000000",
    fontWeight: "500",
    fontFamily: 'system-ui',
})


export const DetailText = styled(Typography)({
    fontSize: "15px",
    color: "#515050",
    display: "flex",
    paddingLeft: "28px",
    fontFamily: 'system-ui',
    marginBottom: "18px",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    fontWeight: "400"
})

export const BookingText = styled(Typography)({
    fontSize: "15px",
    color: "#515050",
    display: "flex",
    fontFamily: 'system-ui',
    marginBottom: "18px",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    fontWeight: "500"
})

export const BusinessName = styled(Typography)({
    fontSize: "18px",
    color: "#000814",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: 'system-ui',
    alignItems: "center",
    gap: "8px",
    fontWeight: "bold"
})

export const BusinessLink = styled(Typography)({
    fontSize: "13px",
    color: "#0466c8",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: 'system-ui',
    alignItems: "center",
    gap: "8px",
    fontWeight: "bold"
})

export const BusinessLikesFoll = styled(Typography)({
    fontSize: "12px",
    color: "#495057",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontFamily: 'system-ui',
    gap: "8px",
    fontWeight: "bold"
})
