import Home from './pages/home/Home';
import Users from './pages/user/Users';
import UserDetails from './pages/user/UserDetails';
import About from './pages/content/About';
import Terms from './pages/content/Terms';
import Privacy from './pages/content/Privacy';
import Faq from './pages/content/Faq';
import ServiceCategory from './pages/service/ServiceCategory';
import Chats from './pages/Chats';
import Profile from './pages/admin/Profile';
import ChangePassword from './pages/admin/ChangePassword';
import Service from './pages/service/Service';
import Support from './pages/support/Support';
import Booking from './pages/booking/Booking';
import BookingDetails from './pages/booking/BookingDetails';
import UserThread from './pages/chats/UserThread';
import BusinessThread from './pages/chats/BusinessThread';
import UserChat from './pages/chats/UserChat';
import BusinessChat from './pages/chats/BusinessChat';
import Transaction from './pages/transction/Transaction';
import AddUser from './pages/user/AddUser';
import Company from './pages/company/Company';
import AddCompany from './pages/company/AddCompany';
import CompanyUser from './pages/company/CompanyUsers';
import EditCompany from './pages/company/Editcompany';

// import Profile from './pages/profile/Profile';
// import ChangePass from './pages/profile/ChangePass';
// import Faq from './pages/content/Faq';

// import FaqDetails from './pages/content/FaqDetails';
// import AddnewF from './pages/content/AddnewF';
// import Premium from './pages/user/Premium';
// import Transaction from './pages/transaction/Transaction';
// import Analytic from './pages/analytics/Analytic';
// import Post from './pages/posts/Post';
// import Remove from './pages/user/Remove';
// import Subscribe from './pages/subscribe/Subscribe';
// import PostDetail from './pages/posts/PostDetail';
// import Assistant from './pages/user/Assistant';
// import AssistDetail from './pages/user/AssistDetail';
// import Topic from './pages/article/Topic';
// import Reason from './pages/article/Reason';
// import ReasonDetail from './pages/article/ReasonDetail';
// import AddReason from './pages/article/AddReason';
// import Contact from './pages/contact/Contact';
// import ContactDet from './pages/contact/ContactDet';
// import ModelCr from './pages/makemodel/ModelCr';
// import Terms from './pages/content/Terms';
// import Privacy from './pages/content/Privacy';
// import ReportCom from './pages/report/ReportCom';
// import ReportPo from './pages/report/ReportPo';
// import ReportPostDetail from './pages/report/ReportPostDetail';
// import SubAdmin from './pages/user/SubAdmin';
// import Group from './pages/group/Group';
// import Directories from './pages/business/Directories';
// import GroupDetail from './pages/group/GroupDetail';
// import Draft from './pages/business/Draft';
// import ArchivedBusiness from './pages/business/ArchivedBusiness';
// import DirectoryDetails from './pages/business/DirectoryDetails';
// import AdminUsers from './pages/user/AdminUsers';
// import ReportedProfiles from './pages/report/ReportedProfiles';

export default [
    { path: "/dashboard", Component: <Home /> },
    { path: "/users", Component: <Users /> },
    { path: "/users/:id", Component: <UserDetails /> },
    { path: "/all-transactions", Component: <Transaction /> },

    // company
    { path: "/companies", Component: <Company /> },
    { path: "/companies/add", Component: <AddCompany /> },
    { path: "/companies/:id", Component: <UserDetails /> },
    { path: "/company-users", Component: <CompanyUser /> },
    { path: "/edit-company/:id", Component: <EditCompany /> },
    
    { path: "/company-users/add", Component: <AddUser /> },

    { path: "/about-management", Component: <About /> },
    { path: "/terms-condition-management", Component: <Terms /> },
    { path: "/privacy-policy-management", Component: <Privacy /> },
    { path: "/faq-management", Component: <Faq /> },
    { path: "/service-category", Component: <ServiceCategory /> },
    { path: "/bookings", Component: <Booking /> },
    { path: "/bookings/:id", Component: <BookingDetails /> },
    { path: "/my-profile", Component: <Profile /> },
    { path: "/change-password", Component: <ChangePassword /> },
    { path: "/all-services", Component: <Service /> },
    { path: "/submitted-supports", Component: <Support /> },

    { path: "/user-thread", Component: <UserThread /> },
    { path: "/business-thread", Component: <BusinessThread /> },
    { path: "/user-chat/:id", Component: <UserChat /> },
    { path: "/business-chat/:id", Component: <BusinessChat /> },

    // { path: "/removed-users", Component: <Remove /> },
    // { path: "/premium-users", Component: <Premium /> },
    // { path: "/sub-admins", Component: <SubAdmin /> },
    // { path: "/users", Component: <AdminUsers /> },
    // { path: "/removed-users", Component: <Remove /> },
    // { path: "/premium-users", Component: <Premium /> },
    // { path: "/contact-us-messages", Component: <Contact /> },
    // { path: "/all-transactions", Component: <Transaction /> },
    // { path: "/reported-comment", Component: <ReportCom /> },
    // { path: "/reported-post", Component: <ReportPo /> },
    // { path: "/subscriptons", Component: <Subscribe /> },
    // { path: "/all-groups", Component: <Group /> },
    // { path: "/reported-profiles", Component: <ReportedProfiles /> },
    // { path: "/all-groups/:id", Component: <GroupDetail /> },
    // { path: "/business-directories", Component: <Directories /> },
    // { path: "/business-directories/:id", Component: <DirectoryDetails /> },
    // { path: "/saved-draft", Component: <Draft /> },
    // { path: "/archived-business-profiles", Component: <ArchivedBusiness /> },
    // { path: "/subscriptons", Component: <Subscribe /> },
    // { path: "/set-faq", Component: <Faq /> },
    // { path: "/make-new-model", Component: <ModelCr /> },
    // { path: "/users-posts", Component: <Post /> },
    // { path: "/reported-post", Component: <ReportPo /> },
    // { path: "/reported-post/reported-post-detail", Component: <ReportPostDetail /> },
    // { path: "/reported-comment", Component: <ReportCom /> },
    // { path: "/users-analytics", Component: <Analytic /> },
    // { path: "/add-new-faq", Component: <AddnewF /> },
    // { path: "/faq/details", Component: <FaqDetails /> },
    // { path: "/set-terms-&-condition", Component: <Terms /> },
    // { path: "/set-privacy-policy", Component: <Privacy /> },
    // { path: "/assistant-contacts/details", Component: <AssistDetail /> },
    // { path: "/assistant-contacts", Component: <Assistant /> },
    // { path: "/users-posts/post-details", Component: <PostDetail /> },
    // { path: "/contact-us-messages", Component: <Contact /> },
    // { path: "/contact-us-messages/details", Component: <ContactDet /> },
    // { path: "/all-transactions", Component: <Transaction /> },
    // { path: "/topics", Component: <Topic /> },
    // { path: "/reason-solution", Component: <Reason /> },
    // { path: "/add-new-reason-solution", Component: <AddReason /> },
    // { path: "/reason-solution/details-reason-solution", Component: <ReasonDetail /> },
    { path: "/*", Component: <Home /> },
]
