import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../../ApiFunction'
import API from '../../Api'
import { BackPaper } from '../../components/Styles'

const BusinessChat = () => {
    const [obj, setObj] = useState({ message: '', id: localStorage.getItem('id') })
    const [userChat, setUserChat] = useState([])
    const [businessChat, setBusinessChat] = useState([])
    const [id, setId] = useState(localStorage.getItem('id'))
    const inputEvent = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value,
        })
    };


    const submitUserChat = async (event) => {
        event.preventDefault();
        try {
            const result = await postRequest(`${API.SEND_FIRST_MESSAGE}`, obj);
            localStorage.setItem('id', result.data.data)
            setId(result.data.data)
        } catch (err) {
            console.error(err.message);
        }
    };

    const chatStartUser = async (event) => {
        event.preventDefault();
        try {
            const result = await postRequest(`${API.SEND_USER_MESSAGE}`, obj);
            getUserChats()
        } catch (err) {
            console.error(err.message);
        }
    };

    const chatStartBusiness = async (event) => {
        event.preventDefault();
        try {
            const result = await postRequest(`${API.SEND_BUSINESS_MESSAGE}`, obj);
            getBusinessChats()
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleChat = () => {
        submitUserChat()
    }


    const getUserChats = async () => {
        try {
            let result = await getRequest(`${API.GET_USER_CHATS}?id=${id}`)
            setUserChat(result.data.data)
        } catch (error) {
            console.log(error.message)
        }
    }

    const getBusinessChats = async () => {
        try {
            let result = await getRequest(`${API.GET_BUSINESS_CHATS}?id=${id}`)
            setBusinessChat(result.data.data)
        } catch (error) {
            console.log(error.message)
        }
    }




    useEffect(() => {
        getUserChats()
        getBusinessChats()
    }, [id])


    return (
        <BackPaper>
            <div className='mt-4'></div>
            <div className='row mt-4'>
                <div className='col-6'>
                    <div className='chat-page'>
                        <div className='text-center w-100'>User side</div>
                        <div style={{ overflow: 'auto', height: "400px" }}>
                            {userChat && userChat.map((element, index) => (
                                <div className='chats' key={index + 1}>
                                    {!element.sendByUser ? <div>{element.message}</div>
                                        :
                                        <div className='d-flex justify-content-end w-100'>{element.message}</div>
                                    }
                                </div>
                            ))}
                        </div>
                        <input type='text' onChange={inputEvent} name="message" id="message" />
                        <button onClick={chatStartUser}>send</button>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='chat-page'>
                        <div className='text-center w-100'>Business side</div>
                        <div style={{ overflow: 'auto', height: "400px" }}>
                            {businessChat && businessChat.map((element, index) => (
                                <div className='chats' key={index + 1}>
                                    {element.sendByUser ? <div>{element.message}</div>
                                        :
                                        <div className='d-flex justify-content-end w-100'>{element.message}</div>
                                    }
                                </div>
                            ))}
                        </div>
                        <input type='text' />
                        <button>send</button>
                    </div>
                </div>
            </div>
        </BackPaper>
    )
}

export default BusinessChat
