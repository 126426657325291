import { Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Toptag from '../../components/topTag/Toptag'
import API, { BASE_URL } from '../../Api'
import { BackPaper } from '../../components/Styles'
import { postRequest } from '../../ApiFunction'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const AddCompany = () => {

    const nav = useNavigate()

    const [obj, setObj] = useState({
        name: ""
    })

    function onChange(e) {
        if (e.target.name === 'count') {
            if (!isNaN(e.target.value)) {
                setObj({
                    ...obj,
                    [e.target.name]: e.target.value.trim()
                })
            }
        } else {
            setObj({
                ...obj,
                [e.target.name]: e.target.value
            })
        }
    }

    async function submitDetails() {
        try {
            const { data } = await postRequest(API.ADD_COMPANY, obj);
            if (data.status) {
                toast.success(data.message)
                nav(-1)
            } else {
                toast.error(data.message)
            }
        } catch (err) {
            toast.error(err)
        }
    }

    return (

        <>
            <Toptag />
            <BackPaper>
                <div className='d-flex justify-content-between align-items-center p-2 mb-3'>
                    <div className='detailHeading'>Add Company</div>
                </div>

                <div className='row form-handler'>
                    <div className='col-md-6 mb-3'>
                        <TextField id="outlined-basic" name="name" value={obj.name} onChange={onChange} label="Company name" variant="outlined" />
                    </div>
                    <div className='col-md-6 mb-3'>
                        <TextField id="outlined-basic" name="count" value={obj.count} onChange={onChange} label="Users count" variant="outlined" />
                    </div>
                    <div className='col-md-6 mb-3'>
                        <Button variant="contained" style={{ color: "white !important" }} onClick={submitDetails}>Submit</Button>
                    </div>
                </div>

            </BackPaper>
        </>
    )
}

export default AddCompany