import React, { useEffect, useState } from 'react'
import { EditOutlined } from '@mui/icons-material'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import Toptag from '../../components/topTag/Toptag'
import {  useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import { getRequest, postRequest } from '../../ApiFunction'
import API from '../../Api'
import toast from 'react-hot-toast'
import { BackPaper, DetailLabel, NavPaper } from '../../components/Styles'



const Faq = () => {
    const [edit, setEdit] = useState(false)
    const [create, setCreate] = useState(false)
    const [all, setAll] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [obj, setObj] = useState({ question: '', answer: '' })
    const navigate = useNavigate();


    const handleEdit = (data) => {
        setEdit(true)
        setObj(data)
    };
    const handleCreate = () => {
        setCreate(true)
    }
    const handleCancel = () => {
        setCreate(false)
        setEdit(false);
    }
    const addData = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value,
        })
    }


    const editFaq = async () => {
        try {
            const result = await postRequest(`${API.EDIT_FAQ}`,obj);
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate("/admin")
                    toast.info("Session expired")
                } else {
                    toast.error(result.data.message)
                }
            } else {
                getAll()
                setEdit(false)
                toast.success(result.data.message)
                setObj({question:"",answer:""})
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    const createFaq = async () => {
        try {
            const result = await postRequest(`${API.CREATE_FAQ}`,obj);
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate("/admin")
                    toast.info("Session expired")
                } else {
                    toast.error(result.data.message)
                }
            } else {
                getAll()
                setCreate(false)
            }
        } catch (err) {
            console.error(err.message)
        }
    }
    const getAll = async () => {
        try {
            const result = await getRequest(`${API.GET_FAQ}`);
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate("/admin")
                    toast.info("Session expired")
                } else {
                    toast.error(result.data.message)
                }
            } else {
                setAll(result.data.data)
                setIsLoading(false)
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    useEffect(() => {
        getAll()
    }, [])
    return (
        <>
            {isLoading ? <Loader /> :
                <>
                    <Toptag />
                    <NavPaper>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <div></div>
                            <div>
                                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                                    {edit || create ? null : <Grid item><button className='tripiBtn' onClick={handleCreate}>Add New</button></Grid>}
                                </Grid>
                            </div>
                        </Grid>
                    </NavPaper>
                    <BackPaper>
                        {edit ?
                           <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems=" flex-start">
                           <Grid item className='w-100'>
                               <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems=" flex-start">
                                   <Grid item>
                                       <DetailLabel>Write your question here.</DetailLabel>
                                   </Grid>
                                   <Grid item className='w-100'>
                                       <textarea type="text" style={{ height: "100px" }} value={obj.question} className="detailBarInput w-100" name='question' id='question' onChange={addData} />
                                   </Grid>
                               </Grid>
                           </Grid>
                           <Grid item className='w-100'>
                               <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems=" flex-start">
                                   <Grid item>
                                       <DetailLabel>Write your answer here.</DetailLabel>
                                   </Grid>
                                   <Grid item className='w-100'>
                                       <textarea type="text" style={{ height: "100px" }} value={obj.answer} className="detailBarInput w-100" name='answer' id='answer' onChange={addData} />
                                   </Grid>
                               </Grid>
                           </Grid>
                           <Grid item>
                               <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                   <Grid item>
                                       <button className='tripiBtn' onClick={handleCancel}>Cancel</button>
                                   </Grid>
                                   <Grid item>
                                       {obj.question === '' || obj.answer === '' ?
                                           <button className='tripiBtn' style={{backgroundColor:"lightgray"}}>Submit</button>
                                           :
                                           <button className='tripiBtn' onClick={editFaq} >Save</button>
                                       }
                                   </Grid>
                               </Grid>
                           </Grid>
                       </Grid>
                            : create ?
                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems=" flex-start">
                                    <Grid item className='w-100'>
                                        <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems=" flex-start">
                                            <Grid item>
                                                <DetailLabel>Write your question here.</DetailLabel>
                                            </Grid>
                                            <Grid item className='w-100'>
                                                <textarea type="text" style={{ height: "100px" }} value={obj.question} className="detailBarInput w-100" name='question' id='question' onChange={addData} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems=" flex-start">
                                            <Grid item>
                                                <DetailLabel>Write your answer here.</DetailLabel>
                                            </Grid>
                                            <Grid item className='w-100'>
                                                <textarea type="text" style={{ height: "100px" }} value={obj.answer} className="detailBarInput w-100" name='answer' id='answer' onChange={addData} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                            <Grid item>
                                                <button className='tripiBtn' onClick={handleCancel}>Cancel</button>
                                            </Grid>
                                            <Grid item>
                                                {obj.question === '' || obj.answer === '' ?
                                                    <button className='tripiBtn' style={{backgroundColor:"lightgray"}}>Submit</button>
                                                    :
                                                    <button className='tripiBtn' onClick={createFaq}>Submit</button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                <>
                                    {all.length > 0 ?
                                        <>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow className='tableHead'>
                                                        <TableCell>Index</TableCell>
                                                        <TableCell>Question</TableCell>
                                                        <TableCell>Answer</TableCell>
                                                        <TableCell align="center">Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {all.map((element, index) => {
                                                        return (
                                                            <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{element.question}</TableCell>
                                                                <TableCell>{element.answer}</TableCell>
                                                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                                                                    <Grid item>
                                                                        <Tooltip title="edit faq" >
                                                                            <EditOutlined className='visibilityIc mt-2' onClick={()=>{handleEdit(element)}} />
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>

                                        </>
                                        :
                                        <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                            <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                            <Grid item><div className="errorMessage">Data not found!</div></Grid>
                                        </Grid>
                                    }
                                </>
                        }

                    </BackPaper>
                </>
            }

        </>
    )
}

export default Faq