import React, { useEffect, useState } from 'react'
import { BackPaper } from '../../components/Styles'
import { getRequest, postRequest } from '../../ApiFunction'
import API from '../../Api'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

const UserThread = () => {
    const [obj, setObj] = useState({ message: '', id: localStorage.getItem('id') })
    const [userChat, setUserChat] = useState([])
    const [businessChat, setBusinessChat] = useState([])
    const [id, setId] = useState(localStorage.getItem('id'))
    
    const inputEvent = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value,
        })
    };


    const getAllThread = async () => {
        try {
            const result = await getRequest(`${API.GET_ALL_USER_THREAD}`)
            setUserChat(result.data.data)
        } catch (err) {
            console.error(err.message);
        }
    };


    const submitUserChat = async (event) => {
        event.preventDefault();
        try {
            const result = await postRequest(`${API.SEND_FIRST_MESSAGE}`, obj);
            localStorage.setItem('id', result.data.data)
            setId(result.data.data)
        } catch (err) {
            console.error(err.message);
        }
    };

    const chatStartUser = async (event) => {
        event.preventDefault();
        try {
            const result = await postRequest(`${API.SEND_USER_MESSAGE}`, obj);
            getUserChats()
        } catch (err) {
            console.error(err.message);
        }
    };

    const chatStartBusiness = async (event) => {
        event.preventDefault();
        try {
            const result = await postRequest(`${API.SEND_BUSINESS_MESSAGE}`, obj);
            getBusinessChats()
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleChat = () => {
        submitUserChat()
    }


    const getUserChats = async () => {
        try {
            let result = await getRequest(`${API.GET_USER_CHATS}?id=${id}`)
            setUserChat(result.data.data)
        } catch (error) {
            console.log(error.message)
        }
    }

    const getBusinessChats = async () => {
        try {
            let result = await getRequest(`${API.GET_BUSINESS_CHATS}?id=${id}`)
            setBusinessChat(result.data.data)
        } catch (error) {
            console.log(error.message)
        }
    }




    useEffect(() => {
        getUserChats()
        getAllThread()
    }, [id])


    return (
        <BackPaper>
            <div className='mt-4'></div>
            <div style={{ marginTop: "50px" }}></div>
            {userChat && userChat.map((element, index) => (
                <NavLink to={'/user-chat/' + element.threadId}>
                    <hr />
                    <div className='d-flex justify-content-between' key={index + 1}>
                        <div className='d-flex gap-2 justify-content-start align-items-center'>
                            <div>Image</div>
                            <div className='d-flex gap-2 justify-content-start flex-column'>
                                <div>{element.business_name}</div>
                                <div>{element.message}</div>
                            </div>
                        </div>
                        <div>{moment(1714707000000).format('L')}</div>
                    </div>
                </NavLink>
            ))}
            <hr />
        </BackPaper>
    )
}

export default UserThread
