import { AddAPhoto, Close, EditOutlined, Search } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton,  Table, TableBody, TableCell, TableHead, TableRow, Tooltip, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import {  getRequest, postRequest } from "../../ApiFunction"
import API, { BASE_URL } from '../../Api'
import { BackPaper, NavPaper } from '../../components/Styles'
import toast from 'react-hot-toast'
import getCroppedImg from '../../components/CroppedImage'
import { ImageCompression } from '../../components/ImageCompression'
import Cropper from 'react-easy-crop'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const ServiceCategory = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [all, setAll] = useState([]);
    const navigate = useNavigate();
    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(false)
    const [key, setKey] = useState("")
    const [image, setImage] = useState("")
    const [showImage, setShowImage] = useState("")
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [image2, setImage2] = useState(null)
    const [croppedAreaPixels, setPixels] = useState(null)
    const [data, setData] = useState({ category_name: "", image: "" })



    const addData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        })
    };

    const handleCreate = () => {
        setCreate(true)
    }

    const handleClose = () => {
        setEdit(false)
        setCreate(false)
        setData({ category_name: "", image: "" })
        setShowImage("")
        cancelCrop()
    }

    const handleEdit = (obj) => {
        setEdit(true)
        setData(obj)
    }


    const searchHandle = (event) => {
        const newQuery = event.target.value.trim();
        setKey(newQuery)
    }





    const handleImageChange = async (e) => {
        setImage2(e.target.files[0])
        const file = e.target.files[0];
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
            let compressed = await ImageCompression(file);
            setImage(URL.createObjectURL(compressed))
        } else {
            toast.error('Please select valid image!')
            setImage2(null)
        }
    }


    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        setPixels(croppedAreaPixels)
    };

    const submitImage = async () => {
        try {
            if (croppedAreaPixels) {
                try {
                    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
                    const file = new File([croppedImage], image2.name, { type: image2.type });
                    setShowImage(URL.createObjectURL(file))
                    setImage("")
                    setImage2(file)
                    // cancelCrop()
                } catch (err) {
                    console.error(err.message)
                    toast.error(err.message)
                }
            }

        } catch (err) {
            console.error(err.message)
        }
    };


    const cancelCrop = async () => {
        try {
            const fileInput = document.getElementById('uploadImage');
            fileInput.value = '';
            setImage('')
            setImage2(null)
            sessionStorage.clear();
        } catch (err) {
            console.error(err.message)
        }
    }


    const handleEditSubmit = async () => {
        try {
            if (data.image ==='' || data.category_name === '') {
                toast.error("All fields are required for creating category", { duration: 2000 })
            } else {
                const formData = new FormData()
                formData.append("_id", data._id)
                formData.append("category_name", data.category_name)
                formData.append("image", image2 !== null ? image2 : data.image)
                const result = await postRequest(`${API.EDIT_CATEGORY}`, formData);
                if (!result.data.status) {
                    if (result.data.code === 203) {
                        localStorage.remove('adminToken');
                        toast.info("Session expired!")
                        localStorage.clear()
                        navigate('/')
                    }
                    toast.error(result.data.message)
                } else {
                    toast.success(result.data.message)
                    setEdit(false)
                    getAll()
                    cancelCrop()
                }
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
        }
    }

    const handleSubmit = async () => {
        try {
            if (image2 === null || data.category_name === '') {
                toast.error("All fields are required for creating category", { duration: 2000 })
            } else {
                const formData = new FormData()
                formData.append("category_name", data.category_name)
                formData.append("image", image2)
                const result = await postRequest(`${API.ADD_CATEGORY}`, formData);
                if (!result.data.status) {
                    if (result.data.code === 203) {
                        localStorage.remove('adminToken');
                        toast.info("Session expired!")
                        localStorage.clear()
                        navigate('/')
                    }
                    toast.error(result.data.message)
                } else {
                    toast.success(result.data.message)
                    setCreate(false)
                    getAll()
                    cancelCrop()
                }
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
        }
    }


    const getAll = async () => {
        const result = await getRequest(`${API.GET_ALL_CATEGORY}?value=${key}`);
        if (!result.data.status) {
            if (result.data.code === 203) {
                localStorage.remove('adminToken');
                toast.info("Session expired!")
                localStorage.clear()
                navigate('/')
            }
            toast.error(result.data.message)
        } else {
            setAll(result.data.data)
            setIsLoading(false)
        }
    }


    useEffect(() => {
        getAll()
    }, [key])





    return (
        <>
            {
                isLoading ? (
                    <Loader />) : (
                    <>
                        <Toptag />
                        <NavPaper>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div></div>
                                <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item>
                                        <div className='searchBar'>
                                            <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                                            <Search className='searchIcon' />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <button className='tripiBtn' onClick={handleCreate}>
                                            Create
                                        </button>
                                    </Grid>
                                </Grid>
                            </div>
                        </NavPaper>
                        <BackPaper>
                            {all.length === 0 && key ?
                                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                    <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                    <Grid item><div className="errorMessage">Category not found!</div></Grid>
                                </Grid> :
                                <>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>S.No</TableCell>
                                                <TableCell>Logo</TableCell>
                                                <TableCell align='center' >Category Name</TableCell>
                                                <TableCell align='center'>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {all.map((element, index) => {
                                                return (<>
                                                    <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>
                                                            {element.image !== '' ? (
                                                                <img src={`${BASE_URL + 'uploads/images/' + element.image}`} alt="user" className='categoryImage' />
                                                            ) : (
                                                                <img src="/images/noImage.jpg" className='categoryImage' alt="" />

                                                            )}
                                                        </TableCell>
                                                        <TableCell align='center'>{element.category_name}</TableCell>
                                                        <TableCell>
                                                            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                                                <Grid item>
                                                                    <Tooltip title="edit category" >
                                                                        <EditOutlined onClick={() => handleEdit(element)} className='visibilityIc' />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>

                                </>
                            }
                        </BackPaper>
                    </>

                )}

            <BootstrapDialog
                fullWidth={true}
                aria-labelledby="customized-dialog-title"
                open={image !== ""}
                maxWidth={'sm'}

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    You need to crop your logo image!
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent dividers>
                    <div className='cropper'>
                        <Cropper
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            style={{ zIndex: "-999" }}
                            aspect={6 / 6}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="tripiBtn" onClick={handleClose}>
                        Discard changes
                    </button>
                    <button className="tripiBtn" autoFocus onClick={submitImage} >
                        Save
                    </button>
                </DialogActions>
            </BootstrapDialog>

            {create || edit ?
                <BootstrapDialog
                    fullWidth={true}
                    aria-labelledby="customized-dialog-title"
                    open={create || edit}
                    maxWidth={'sm'}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        You can create or edit category!
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={6} lg={5} item >
                                <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                                    <Grid item>
                                        {showImage !== "" ?
                                            <img src={showImage} className='user-detailsImage' alt="" />
                                            :
                                            <>
                                                {data.image !== '' ? (
                                                    <img src={`${BASE_URL}uploads/images/${data.image}`} alt="user" className='user-detailsImage' />
                                                ) : (
                                                    <img src="/images/noImage.jpg" className='user-detailsImage' alt="" />

                                                )}
                                            </>}
                                    </Grid>
                                    <Grid item>
                                        <label htmlFor='uploadImage'>
                                            <Tooltip title="click to change image">
                                                <AddAPhoto className='imageUploadIcon' />
                                            </Tooltip>
                                        </label>
                                        <input id='uploadImage' onChange={handleImageChange} accept='image/*' type='file' style={{ display: "none" }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} lg={7} item>
                                <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item>Category Name:</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" value={data.category_name} name="category_name" id='category_name' onChange={addData} className="detailBarInput" />
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <button className="tripiBtn" onClick={handleClose}>
                            Discard changes
                        </button>
                        {create ?
                            <button className="tripiBtn" autoFocus onClick={handleSubmit}>
                                Submit
                            </button>
                            :
                            <button className="tripiBtn" autoFocus onClick={handleEditSubmit}>
                                Save
                            </button>
                        }
                    </DialogActions>
                </BootstrapDialog>
                : null}

        </>
    )
}

export default ServiceCategory









