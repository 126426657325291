import { AccessTime, Block, Delete, LockClock, RemoveRedEye, Search } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogTitle, FormControl, Grid, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import { deleteRequest, getRequest, postRequest } from "../../ApiFunction"
import { useSelector } from 'react-redux'
import API, { BASE_URL } from '../../Api'
import { BackPaper, NavPaper } from '../../components/Styles'
import moment from 'moment'
import codes from 'country-calling-code';
import toast from 'react-hot-toast'


const Transaction = () => {
    const transactionCount = useSelector(state => state.count.counts.transactionCount);
    const [isLoading, setIsLoading] = useState(true)
    const error = useSelector(state => state.count.error);
    const [all, setAll] = useState([]);
    const navigate = useNavigate();
    const [row, setRow] = useState('10');
    const [skip, setSkip] = useState('0')
    const [key, setKey] = useState("")
    const [open, setOpen] = React.useState(false);
    const [remopen, setRemOpen] = React.useState(false);
    const [id, setId] = useState()
    const [status, setStatus] = useState()




    if (error === 203) {
        localStorage.clear();
        navigate('/')
        toast.info("Session expired")
    }




    const handleBlockOpen = (id, status) => {
        setId(id)
        setStatus(status)
        setOpen(true);
    };

    const handleBlock = () => {
        setOpen(false);
    };

    const handleRemoveOpen = (id) => {
        setId(id)
        setRemOpen(true);
    };

    const handleRemove = () => {
        setRemOpen(false);
    };

    const searchHandle = (event) => {
        const newQuery = event.target.value.trim();
        setKey(newQuery)
        setSkip(0);
    }







    const removeUser = async (id) => {
        try {
            const result = await deleteRequest(`${API.DELETE_USER}`, { id: id })
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.remove('adminToken');
                    localStorage.clear()
                    navigate('/')
                    toast.info("Session expired!")
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                getAll()
                setRemOpen(false)
            }
        } catch (error) {
            console.log(error.message)
        }
    }



    const BlockUser = async (id) => {
        try {
            const result = await postRequest(`${API.BLOCK_UNBLOCK_USER}`, { id: id });
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.remove('adminToken');
                    localStorage.clear()
                    navigate('/')
                    toast.info("Session expired!")
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                getAll()
                setOpen(false)
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    const getAll = async () => {
        const result = await getRequest(`${API.GET_ALL_TRANSACTION}?value=${key}&row=${row}&skip=${skip}`);
        if (!result.data.status) {
            if (result.data.code === 203) {
                localStorage.remove('adminToken');
                toast.info("Session expired!")
                localStorage.clear()
                navigate('/')
            }
            toast.error(result.data.message)
        } else {
            setAll(result.data.data)
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getAll()
    }, [key, row, skip])


    const handleChange = (e) => {
        if (e.target.value === transactionCount) {
            setSkip(0)
            setRow(transactionCount)
        } else {
            setRow(e.target.value)
        }
    };
    const skipPagination = (e, page) => {
        setSkip((page - 1) * row)
    }


    return (
        <>


            {
                isLoading ? (
                    <Loader />) : (
                    <>
                        <Toptag />
                        <BackPaper>
                            <div className='d-flex justify-content-end align-items-center gap-4'>
                                <div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Transaction: {transactionCount}</div>
                                <div className='searchBar'>
                                    <input type="text" placeholder='Search user' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                                    <Search className='searchIcon' />
                                </div>
                            </div>
                            {all.length === 0 && key ?
                                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                    <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                    <Grid item><div className="errorMessage">User not found!</div></Grid>
                                </Grid> :
                                <>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>S.No</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Transaction ID</TableCell>
                                                <TableCell align='center' >User</TableCell>
                                                <TableCell align='center'>Transaction Date</TableCell>
                                                <TableCell align='center'>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {all.map((element, index) => {
                                                return (<>
                                                    <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                                                        <TableCell align='center'>{index + 1}</TableCell>
                                                        <TableCell align='center'>
                                                            <div className="d-flex gap-3 align-items-center">
                                                                <div>${element.amount}</div>
                                                                <span class="label-incomplete">{element.status} <AccessTime style={{fontSize:'15px'}} /></span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>{element.transactionId}</TableCell>
                                                        <TableCell align='center'>{element.name}</TableCell>
                                                        <TableCell align='center'>{moment(element.createdAt).format('LL')}</TableCell>
                                                        <TableCell>
                                                            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                                                <Grid item>
                                                                    <Tooltip title="view profile" >
                                                                        <NavLink to={'/users/' + element.userId} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow >
                                                </>
                                                )

                                            })}
                                        </TableBody>
                                    </Table>

                                </>
                            }
                            {/* dilogue for block user */}
                            <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">
                                    {status ? ("Are you sure you want to unblock this user?") : ("Are you sure you want to block this user?")}
                                </DialogTitle>
                                <DialogActions>
                                    <Button onClick={handleBlock}>Cancel</Button>
                                    <Button onClick={() => { BlockUser(id) }} >{status ? 'unBlock' : 'Block'}</Button>
                                </DialogActions>
                            </Dialog>

                            {/* dilogue for delete user */}
                            <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">
                                    {"Are you sure you want to delete this user from the app?"}
                                </DialogTitle>
                                <DialogActions>
                                    <Button onClick={handleRemove}>No</Button>
                                    <Button onClick={() => { removeUser(id) }} >Yes</Button>
                                </DialogActions>
                            </Dialog>

                        </BackPaper>
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
                            <div>
                                <Pagination count={Math.ceil(transactionCount / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
                            </div>
                        </div>
                    </>

                )
            }

        </>
    )
}

export default Transaction









