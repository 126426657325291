import { Block, Delete, Edit, RemoveRedEye, Search } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogTitle, FormControl, Grid, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import { deleteRequest, getRequest, postRequest } from "../../ApiFunction"
import { useSelector } from 'react-redux'
import API, { BASE_URL } from '../../Api'
import { BackPaper, NavPaper } from '../../components/Styles'
import moment from 'moment'
import codes from 'country-calling-code';
import toast from 'react-hot-toast'


const Company = () => {
  // const usersCount = useSelector(state => state.count.counts.userCount);
  const [isLoading, setIsLoading] = useState(true)
  const error = useSelector(state => state.count.error);
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [row, setRow] = useState('10');
  const [skip, setSkip] = useState('0')
  const [key, setKey] = useState("")
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [id, setId] = useState()
  const [status, setStatus] = useState()
  const [usersCount, setUsersCount] = useState(0)



  if (error === 203) {
    localStorage.clear();
    navigate('/')
    toast.info("Session expired")
  }




  const handleBlockOpen = (id, status) => {
    setId(id)
    setStatus(status)
    setOpen(true);
  };

  const handleBlock = () => {
    setOpen(false);
  };

  const handleRemoveOpen = (id) => {
    setId(id)
    setRemOpen(true);
  };

  const handleRemove = () => {
    setRemOpen(false);
  };

  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery)
    setSkip(0);
  }







  const removeUser = async (id) => {
    try {
      const result = await deleteRequest(`${API.DELETE_USER}`, { id: id })
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.remove('adminToken');
          localStorage.clear()
          navigate('/')
          toast.info("Session expired!")
        }
        toast.error(result.data.message)
      } else {
        toast.success(result.data.message)
        getAll()
        setRemOpen(false)
      }
    } catch (error) {
      console.log(error.message)
    }
  }



  const BlockUser = async (id, status) => {
    try {
      const result = await postRequest(`${API.UPDATE_STATUS}`, { id, status });
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.remove('adminToken');
          localStorage.clear()
          navigate('/')
          toast.info("Session expired!")
        }
        toast.error(result.data.message)
      } else {
        toast.success(result.data.message)
        getAll()
        setOpen(false)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const getAll = async () => {
    const result = await getRequest(`${API.GET_ALL_COMPANIES}?value=${key}&row=${row}&skip=${skip}`);
    if (!result.data.status) {
      if (result.data.code === 203) {
        localStorage.remove('adminToken');
        toast.info("Session expired!")
        localStorage.clear()
        navigate('/')
      }
      toast.error(result.data.message)
    } else {
      setAll(result.data.data)
      setUsersCount(result.data.total_count)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getAll()
  }, [key, row, skip])


  const handleChange = (e) => {
    if (e.target.value === usersCount) {
      setSkip(0)
      setRow(usersCount)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }


  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                  <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Users: {usersCount}</div></Grid>
                    <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid>
                    <Grid item>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard-label"
                          value={row}
                          onChange={handleChange}
                        >
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="30">30</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='searchBar mb-0'>
                    <input type="text" placeholder='Search user' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                    <Search className='searchIcon' />
                  </div>
                  <Link to="add" className='ms-5' style={{ color: "blue !important", textDecoration: "underline !important" }}>Add Company</Link>
                </div>
              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 && key ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">User not found!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell align='center' >Name</TableCell>
                        <TableCell align='center'>Joined Since</TableCell>
                        <TableCell align='center'>Users Count</TableCell>
                        <TableCell align='center'>Parallel Users Count</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align='center'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {all.map((element, index) => {
                        return (<>
                          <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell align='center'>{element.name}</TableCell>
                            <TableCell align='center'>{moment(element.createdAt).format('LL')}</TableCell>
                            <TableCell align='center'>{element.count}</TableCell>
                            <TableCell align='center'>{element.total_count}</TableCell>
                            <TableCell>
                              {element.disabled ? (
                                <Chip label="Inactive" className="statusChipBlock" />)
                                : (
                                  <Chip label="Active" className="statusChipActive" />)
                              }</TableCell>
                            <TableCell>
                              <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="View Users" >
                                    <NavLink to={'/company-users?company=' + element._id} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <Tooltip title={!element.disabled ? 'Deactivate Company' : 'Activate Company'} >
                                    {element.disabled ?
                                      <Block className="blockIcon" style={{ color: "green" }} onClick={() => { handleBlockOpen(element._id, element.disabled) }} />
                                      :
                                      <Block className="blockIcon" style={{ color: "red" }} onClick={() => { handleBlockOpen(element._id, element.disabled) }} />
                                    }
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <Tooltip title="Edit Company" >
                                    <NavLink to={'/edit-company/' + element._id} ><Edit className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow >
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }
              {/* dilogue for block user */}
              <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {status ? ("Are you sure you want to active this company?") : ("Are you sure you want to deactivate this company?")}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleBlock}>Cancel</Button>
                  <Button onClick={() => { BlockUser(id, !status) }} >{status ? 'Activate' : 'Deactivate'}</Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for delete user */}
              <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure you want to delete this user from the app?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleRemove}>No</Button>
                  <Button onClick={() => { removeUser(id) }} >Yes</Button>
                </DialogActions>
              </Dialog>

            </BackPaper>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(usersCount / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>
          </>

        )
      }

    </>
  )
}

export default Company