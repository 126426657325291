import { AccountCircleOutlined, AddAPhoto, BuildOutlined, Close, ConstructionOutlined, Email, EmailOutlined, Error, LocationOn, PersonOutlineOutlined, ScoreboardOutlined } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Input, MenuItem, Select, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Toptag from '../../components/topTag/Toptag'
import Loader from '../../components/loader/Loader'
import API, { BASE_URL } from '../../Api'
import { BackPaper, BusinessLink, BusinessName, DetailLabel, DetailText } from '../../components/Styles'
import moment from 'moment'
import { postRequest } from '../../ApiFunction'
import toast from 'react-hot-toast'
import ApartmentIcon from '@mui/icons-material/Apartment';
import ChangeCompany from './ChangeCompany'
// import { DatePicker } from '@mui/x-date-pickers';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const UserDetails = ({ objs }) => {
    const router = useParams()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [edit, setEdit] = useState(false)
    const [sub, setSub] = useState(false)
    const [message, setMessage] = useState('')
    const [image, setImage] = useState("")
    const [image2, setImage2] = useState(null)
    const [obj, setObj] = useState({ name: "", email: "", role: "", mechanic_knowledge: "", general_car_knowledge: "" })
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const [open, setOpen] = React.useState(false);

    const imageChange = (e) => {
        setImage2(e.target.files[0])
        setImage(URL.createObjectURL(e.target.files[0]))
    }




    const addData = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value,
        })
    }


    const getAll = async () => {
        const result = await postRequest(`${API.GET_USER_DETAILS}`, { id: router.id });
        console.log(result, "eve")
        if (!result.data.status) {
            if (result.data.code === 203) {
                localStorage.remove('adminToken');
                localStorage.clear()
                navigate('/')
                toast.info("Session expired!")
            }
            toast.error(result.data.message)
        } else {
            setObj(result.data.data)
            await delay(1000);
            setIsLoading(false)
        }
    }

    const [companytoggle, setToggle] = useState(false)



    const BlockUser = async (id) => {
        try {
            const result = await postRequest(`${API.BLOCK_UNBLOCK_USER}`, { id: id });
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.remove('adminToken');
                    localStorage.clear()
                    navigate('/')
                    toast.info("Session expired!")
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                getAll()
                setOpen(false)
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    const today = new Date().toISOString().split("T")[0];




    const update = async () => {
        let id = router.id
        const formData = new FormData()
        formData.append("name", obj.name)
        formData.append("role", obj.role)
        formData.append("email", obj.email)
        formData.append("mechanic_knowledge", obj.mechanic_knowledge)
        formData.append("general_car_knowledge", obj.general_car_knowledge)
        formData.append("profilePic", image2)
        // const result = await APIFunctions.edit_user(id, formData);
        // if (!result.data.status) {
        //   if (result.data.code === 205) {
        //     setMessage(result.data.message)
        //   } else if (result.data.code === 401) {
        //     localStorage.clear('admintoken');
        //     sessionStorage.clear('admintoken');
        //     navigate('/admin')
        //     toast.info("Session expired")
        //   }
        // } else {
        //   setIsLoading(false)
        //   setEdit(false)
        //   setOpen(false)
        //   setImage('')
        //   setImage2(null)
        //   getAll()
        //   setMessage('')
        //   toast.success("Profile has been updated successfully!")
        // }
    }

    const [subscription, setSubscription] = useState({
        plan: 'month',
        start_date: '',
        userId: router.id
    })

    const discardChanges = () => {
        setOpen(false)
        setEdit(false)
        getAll()
        setImage('')
        setSub(false)
        setImage2(null)
        setToggle(false)
    }

    const handleClose = () => {
        setOpen(false)
        setEdit(false)
        setImage('')
        setSub(false)
        setImage2(null)
        setToggle(false)
    }
    useEffect(() => {
        setIsLoading(true)
        getAll()
    }, [])

    async function addSubscription() {
        console.log(subscription);
        if (subscription.start_date === "") {
            toast.error("Start date is required")
            return
        }

        try {
            const result = await postRequest(`${API.ADD_SUBSCRIPTION}`, subscription);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.remove('adminToken');
                    localStorage.clear()
                    navigate('/')
                    toast.info("Session expired!")
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                getAll()
                setSub(false)
            }
        } catch (err) {
            toast.error(err)
        }
    }

    function dateGiver(timestamp) {
        let oneDayInMilliseconds = 30 * 60 * 60 * 1001; // 1 day in milliseconds
        let newDate = new Date(timestamp + oneDayInMilliseconds).toISOString().split("T")[0];
        return newDate
    }

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />
                    <BackPaper>
                        <div className='d-flex justify-content-between align-items-center p-2'>
                            <div className='detailHeading'>Account Information</div>
                            <div className='userDate'>This Profile is created on {moment(obj.createdAt).format('LLL')}</div>
                        </div>
                        <div className='d-flex p-2  flex-row gap-3 justify-content-start align-items-center'>
                            {obj.profile_pic !== '' ? (
                                <>
                                    <img src={`${BASE_URL}uploads/images/${obj.profile_pic}`} alt="user" className='user-detailsImage' />
                                </>
                            ) : (
                                <img src="/images/blank_pic.png" className='user-detailsImage' alt="" />

                            )}
                            <div className='d-flex flex-column gap-2 justify-content-start align-items-start'>
                                <BusinessName>{obj.name} {obj.premiumStatus ? <Tooltip title="Premium User"><img src="/images/emailVerifiedIcon.svg" alt="emailVerifiedIcon.svg" /></Tooltip> : null} {obj.isBlocked ? <i style={{ color: "red" }} className="fa-solid fa-ban"></i> : null}</BusinessName>
                                <BusinessLink><Email style={{ fontSize: "13px" }} />{obj.email}</BusinessLink>
                                <div className='d-flex flex-row gap-2 justify-content-start align-items-start'>
                                    {!obj.isBlocked ?
                                        <span className="badge bg-success">Active</span>
                                        :
                                        <span className="badge bg-danger">Blocked</span>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className='w-100 text-end d-flex gap-4 justify-content-end'>
                            {obj.isBlocked ? (
                                <button className='tripiBtn' onClick={() => { BlockUser(obj._id) }}>UnBlock User</button>)
                                :
                                (<button className='tripiBtn' onClick={() => { BlockUser(obj._id) }}>Block User</button>)
                            }
                            {!edit ?
                                <button className='tripiBtn' onClick={handlecChange}>Edit Profile</button>
                                :
                                null
                            }
                        </div> */}
                        <hr />
                        <div className='row'>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                <h6>Personal Details</h6>
                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <DetailLabel><AccountCircleOutlined className='detailIcon' /> Name:</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <input type="text" disabled value={obj.name} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>
                                        <DetailLabel><EmailOutlined className='detailIcon' /> Email:</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <input type="text" disabled value={obj.email} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>
                                        <DetailLabel><PersonOutlineOutlined className='detailIcon' /> Role:</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <input type="text" disabled value={obj.role} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>
                                        <DetailLabel><ApartmentIcon className='detailIcon' /> Company:</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100 d-flex justify-content-between align-items-center'>
                                        {obj.company !== "" ? obj.company : "This user is not associated with any company"}
                                        <Button variant='outlined' onClick={() => setToggle(true)} type='button'>{obj.company !== "" ? "Change Company" : "Associate now"}</Button>
                                        {/* <input type="text" disabled value={obj.role} className="detailBarInput" /> */}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                <h6>Membership Details</h6>
                                {/* {JSON.stringify(obj)} */}
                                <Grid item>
                                    <DetailLabel> Subscription Status</DetailLabel>
                                    <Grid item className='w-100 mb-3'>
                                        {obj.plan_status === "Active" ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Expired</span>}
                                        <Button variant='success' onClick={() => setSub(!sub)}>{obj.upcomingStartDate && obj.upcomingStartDate > moment().valueOf() ? "Change Plan" : "Assign Plan"}</Button><br/>
                                        {obj.upcomingStartDate && (obj.upcomingStartDate > moment().valueOf()) ? <small>{"An upcoming plan is already assigned, It will start on "+moment(obj.upcomingStartDate).format("DD MMM YYYY")}</small> : null}
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <DetailLabel> Subscription Plan</DetailLabel>
                                    <Grid item className='w-100 mb-3'>
                                        {obj.plan_type}
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <DetailLabel> Start Date</DetailLabel>
                                    <Grid item className='w-100 mb-3'>
                                        {obj.startDate <= 0 ? "N/A" : moment(obj.startDate).format("DD MMM YYYY hh:mm A")}
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <DetailLabel> Expiry Date</DetailLabel>
                                    <Grid item className='w-100 mb-3'>
                                        {obj.endDate <= 0 ? "N/A" : moment(obj.endDate).format("DD MMM YYYY hh:mm A")}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <BootstrapDialog
                            fullWidth={true}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            maxWidth={'sm'}
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                Edit Profile
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </IconButton>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid xs={12} sm={12} md={6} lg={5} item >
                                        <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                {image !== "" ?
                                                    <img src={image} className='user-detailsImage' alt="" />
                                                    :
                                                    <>
                                                        {obj.profilePic !== '' ? (
                                                            <>
                                                                {obj.profilePic && obj.profilePic.startsWith("https") ?
                                                                    <img src={obj.profilePic} alt="user" className='user-detailsImage' />
                                                                    :
                                                                    <img src={`${BASE_URL}uploads/images/profiles/${obj.profilePic}`} alt="user" className='user-detailsImage' />
                                                                }
                                                            </>
                                                        ) : (
                                                            <img src="/images/blank_pic.png" className='user-detailsImage' alt="" />

                                                        )}
                                                    </>}
                                            </Grid>
                                            <Grid item>
                                                <label htmlFor='uploadImage'>
                                                    <Tooltip title="click to change image">
                                                        <AddAPhoto className='imageUploadIcon' />
                                                    </Tooltip>
                                                </label>
                                                <input id='uploadImage' onChange={imageChange} accept='image/*' type='file' style={{ display: "none" }} />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={7} item>
                                        <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start">
                                            <Grid item>Name:</Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <input type="text" disabled={!edit ? true : false} value={obj.name} name="name" id='name' onChange={addData} className="detailBarInput" style={{ border: message !== '' && message.startsWith('name') ? "1px solid red" : null }} />
                                            </Grid>
                                            <Grid item>Email:</Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <input type="text" disabled={!edit ? true : false} value={obj.email} className="detailBarInput" name='email' id='email' onChange={addData} style={{ border: message !== '' && message.startsWith('email') ? "1px solid red" : null }} />
                                            </Grid>
                                            <Grid item>Role:</Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <select disabled={!edit ? true : false} value={obj.role} className="detailBarInput" name='role' id='role' onChange={addData} style={{ border: message !== '' && message.startsWith('role') ? "1px solid red" : null }}>
                                                    <option value="">Select Role:-</option>
                                                    <option value="user">User</option>
                                                    <option value="sub-admin">Admin</option>
                                                </select>
                                            </Grid>
                                            <Grid item>Mechanic Knowledge:</Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <select disabled={!edit ? true : false} value={obj.mechanic_knowledge} name="mechanic_knowledge" id="mechanic_knowledge" className="detailBarInput" onChange={addData} style={{ border: message !== '' && message.startsWith('mechanic_knowledge') ? "1px solid red" : null }}>
                                                    <option value="">Select Level:-</option>
                                                    <option value="beginner">Beginner</option>
                                                    <option value="internediate">Intermediate</option>
                                                    <option value="expert">Expert</option>
                                                </select>
                                            </Grid>
                                            <Grid item>General Car Knowledge:</Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <select disabled={!edit ? true : false} value={obj.general_car_knowledge} name="general_car_knowledge" id="general_car_knowledge" className="detailBarInput" onChange={addData} style={{ border: message !== '' && message.startsWith('general_car_knowledge') ? "1px solid red" : null }}>
                                                    <option value="">Select Level:-</option>
                                                    <option value="beginner">Beginner</option>
                                                    <option value="internediate">Intermediate</option>
                                                    <option value="expert">Expert</option>
                                                </select>
                                            </Grid>
                                            <Grid item>
                                                <div className='errText'>
                                                    {message !== '' ? <div className='errText'><Error style={{ fontSize: "18px" }} /> All feilds are required!</div> : null}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <buttin className="newBtn2" onClick={discardChanges}>
                                    Discard changes
                                </buttin>
                                <buttin className="newBtn" autoFocus onClick={update}>
                                    Save
                                </buttin>
                            </DialogActions>
                        </BootstrapDialog>





                        {/* for subscription */}
                        <BootstrapDialog
                            fullWidth={true}
                            aria-labelledby="customized-dialog-title"
                            open={sub}
                            maxWidth={'sm'}
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                Add Subscription
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </IconButton>
                            <DialogContent dividers>
                                {/* <Grid container spacing={2}> */}

                                {/* <DatePicker label="Basic date picker" /> */}
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='w-100'>
                                            <label>Plan start date : </label><br />
                                            <input onChange={(e) => setSubscription({
                                                ...subscription,
                                                start_date: e.target.value
                                            })} min={obj.endDate > moment().valueOf() ? dateGiver(obj.endDate) : today} className='datepicker' type='date' />
                                        </div>

                                    </div>

                                    <div className='col-md-6'>
                                        <label>Plan Type : </label><br /><br />
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={subscription.plan}
                                            label="Plan Type"
                                            onChange={(e) => {
                                                setSubscription({
                                                    ...subscription,
                                                    plan: e.target.value
                                                })
                                            }}>
                                            <MenuItem value={"month"}>Monthly</MenuItem>
                                            <MenuItem value={"annum"}>Annual</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                {/* </Grid> */}
                            </DialogContent>
                            <DialogActions>
                                <Button className="newBtn2" variant='outlined' onClick={addSubscription}>
                                    Submit
                                </Button>
                                <Button className="newBtn" variant='outlined' autoFocus onClick={discardChanges}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>






                        {/* for company update */}
                        <BootstrapDialog
                            fullWidth={true}
                            aria-labelledby="customized-dialog-title"
                            open={companytoggle}
                            maxWidth={'sm'}
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                Associate user to the company
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </IconButton>
                            <DialogContent dividers>
                                <ChangeCompany id={obj.companyId} onClose={() => {
                                    discardChanges();
                                    getAll()
                                }} userId={obj._id} />
                            </DialogContent>
                        </BootstrapDialog>
                    </BackPaper>
                </>}

        </>
    )
}

export default UserDetails