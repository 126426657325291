import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./Toolbar";
import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/topTag/Toptag";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";
import { getRequest, postRequest } from "../../ApiFunction";
import API from "../../Api";
import toast from "react-hot-toast";



const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const About = () => {
    const [state, setState] = useState('');
    const navigate = useNavigate();

    const handleChange = data => {
        setState(data)
    };
    const updateContent = async () => {
        let data = {
            content: state,
            type: 'about'
        }
        try {
            const result = await postRequest(`${API.EDIT_CONTENT}`, data);
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate("/admin")
                    toast.info("Session expired")
                } else {
                    toast.error(result.data.message)
                }
            } else {
                toast.success(result.data.message)
                getAll()
            }
        } catch (err) {
            console.error(err.message)
        }
    }
    const getAll = async () => {
        try {
            const result = await getRequest(`${API.GET_ABOUT}`);
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate("/admin")
                    toast.info("Session expired")
                } else {
                    toast.error(result.data.message)
                }
            } else {
                setState(result.data.data.content)
            }
        } catch (err) {
            console.error(err.message)
        }
    }
    useEffect(() => {
        getAll()
    }, [])


    return (
        <div className='container-fluid'>
            <Toptag />
            <BackPaper>
                <EditorToolbar />
                <ReactQuill
                    style={{ height: '470px', overflow: "auto" }}
                    theme="snow"
                    id='quillState'
                    value={state}
                    onChange={handleChange}
                    placeholder={"Write something..."}
                    modules={modules}
                    formats={formats}
                />
                <Grid container style={{ marginTop: "10px" }} display="flex" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                        <button className="tripiBtn" onClick={updateContent}>SUBMIT</button>
                    </Grid>
                </Grid>
            </BackPaper>
        </div>
    )
}

export default About