import { Close, ContactSupport, Delete, Search } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import { getRequest, postRequest } from "../../ApiFunction"
import {  useSelector } from 'react-redux'
import API from '../../Api'
import { BackPaper, DetailLabel, NavPaper } from '../../components/Styles'
import codes from 'country-calling-code';
import toast from 'react-hot-toast'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Support = () => {
  const supportCount = useSelector(state => state.count.counts.supportCount);
  const [isLoading, setIsLoading] = useState(true)
  const [details, setDetails] = useState(false)
  const error = useSelector(state => state.count.error);
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [row, setRow] = useState('10');
  const [skip, setSkip] = useState('0')
  const [key, setKey] = useState("")
  const [remopen, setRemOpen] = React.useState(false);
  const [id, setId] = useState()
  const [obj, setObj] = useState({})



  if (error === 203) {
    localStorage.clear();
    navigate('/')
    toast.info("Session expired")
  }

  const handleClose = () => {
    setDetails(false)
  }

  const handleView = (data) => {
    setDetails(true)
    setObj(data)
  }


  const handleRemoveOpen = (id) => {
    setId(id)
    setRemOpen(true);
  };

  const handleRemove = () => {
    setRemOpen(false);
  };

  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery)
    setSkip(0);
  }







  const removeUser = async (id) => {
    try {
      const result = await postRequest(`${API.DELETE_SUPPORT}`, { id: id })
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.remove('adminToken');
          localStorage.clear()
          navigate('/')
          toast.info("Session expired!")
        }
        toast.error(result.data.message)
      } else {
        toast.success(result.data.message)
        getAll()
        setRemOpen(false)
      }
    } catch (error) {
      console.log(error.message)
    }
  }





  const getAll = async () => {
    const result = await getRequest(`${API.GET_ALL_SUPPORT}?value=${key}`);
    if (!result.data.status) {
      if (result.data.code === 203) {
        localStorage.remove('adminToken');
        toast.info("Session expired!")
        localStorage.clear()
        navigate('/')
      }
      toast.error(result.data.message)
    } else {
      setAll(result.data.data)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getAll()
  }, [key])


  const handleChange = (e) => {
    if (e.target.value === supportCount) {
      setSkip(0)
      setRow(supportCount)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }


  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Users: {supportCount}</div></Grid>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={row}
                        onChange={handleChange}
                      >
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <div className='searchBar'>
                      <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                      <Search className='searchIcon' />
                    </div>
                  </Grid>
                </Grid>

              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 && key ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">Data not found!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell align='center'>Title</TableCell>
                        <TableCell align='center'>Name</TableCell>
                        <TableCell align='center'>Email</TableCell>
                        <TableCell align='center'>Role</TableCell>
                        <TableCell align='center'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {all.map((element, index) => {
                        return (<>
                          {element.businessId === null ?
                            <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell align='center'>{element.title}</TableCell>
                              <TableCell align='center'>{element.user_name}</TableCell>
                              <TableCell align='center'>
                                <NavLink to={'/users/' + element.userId}>
                                  <Tooltip title="View user details">
                                    <div className="viewText" onClick={() => handleView(element)}>{element.user_email}</div>
                                  </Tooltip>
                                </NavLink>
                              </TableCell>
                              <TableCell align='center'><div className='statusChipPending'>User</div></TableCell>
                              <TableCell>
                                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                  <Grid item>
                                    <Tooltip title="View details">
                                      <ContactSupport className="userListDelete" onClick={() => handleView(element)} />
                                    </Tooltip>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip title="Delete user from app" >
                                      <Delete className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                            :
                            <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell align='center'>{element.title}</TableCell>
                              <TableCell align='center'>{element.business_name}</TableCell>
                              <TableCell align='center'>
                                <NavLink to={'/providers/' + element.businessId}>
                                  <Tooltip title="View business details">
                                    <div className="viewText" onClick={() => handleView(element)}>{element.business_email}</div>
                                  </Tooltip>
                                </NavLink>
                              </TableCell>
                              <TableCell align='center'><div className='statusChipPending'>Business</div></TableCell>
                              <TableCell>
                                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                  <Grid item>
                                    <Tooltip title="Delete user from app" >
                                      <Delete className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          }
                        </>
                        )
                      })}
                    </TableBody>
                  </Table>

                </>
              }

              {/* dilogue for delete user */}
              <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to delete this details?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleRemove}>No</Button>
                  <Button onClick={() => { removeUser(id) }} >Yes</Button>
                </DialogActions>
              </Dialog>

            </BackPaper>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(supportCount / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>
          </>

        )}
      <BootstrapDialog
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={details}
        maxWidth={'sm'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Contact Support details
        </DialogTitle>
        <IconButton onClick={handleClose}
          aria-label="close" sx={{
            position: 'absolute',
            right: 8, top: 8, color: (theme) => theme.palette.grey[500],
          }} >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <DetailLabel className='mt-2'> Title</DetailLabel>
          <textarea type="text" disabled value={obj.title} className="detailBarInput" style={{ height: "100px" }}/>
          <DetailLabel className='mt-2'> Description</DetailLabel>
          <textarea type="text" disabled value={obj.description} className="detailBarInput" style={{ height: "200px" }} />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </BootstrapDialog>
    </>
  )
}

export default Support









