import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Toptag from '../../components/topTag/Toptag'
import API, { BASE_URL } from '../../Api'
import { BackPaper } from '../../components/Styles'
import { getRequest, postRequest } from '../../ApiFunction'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import ImportData from './ImportData'

const AddUser = () => {

    const nav = useNavigate()
    const [companies, setCompanies] = useState([])

    const [obj, setObj] = useState({
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        companyId: ""
    })

    function onChange(e) {
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        })
    }

    async function submitDetails() {
        try {
            const { data } = await postRequest(API.ADD_USER, obj);
            if (data.status) {
                toast.success(data.message)
                nav(-1)
            } else {
                toast.error(data.message)
            }
        } catch (err) {
            toast.error(err)
        }
    }

    const getCompanies = async () => {
        const result = await getRequest(`${API.COMPANY_LIST}`);
        if (!result.data.status) {
            if (result.data.code === 203) {
                localStorage.remove('adminToken');
                toast.info("Session expired!")
                localStorage.clear()
                nav('/')
            }
            toast.error(result.data.message)
        } else {
            setCompanies(result.data.data)
            //   setIsLoading(false)
        }
    }

    const [value, setValue] = useState('1');


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getCompanies()
    }, [])

    return (

        <>
            <Toptag />
            <BackPaper>
                <div className='d-flex justify-content-between align-items-center p-2'>
                    <div className='detailHeading'>Add User</div>
                </div>

                <TabContext value={value}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Form" value="1" />
                        <Tab label="IMPORT DATA" value="2" />
                    </TabList>
                </TabContext>

                <br/>

                {value == 1 ? <div className='row form-handler'>
                    <div className='col-md-6 mb-3'>
                        <TextField id="outlined-basic" name="name" value={obj.name} onChange={onChange} label="Username" variant="outlined" />
                    </div>
                    <div className='col-md-6 mb-3'>
                        <TextField id="outlined-basic" name="email" value={obj.email} onChange={onChange} label="Email" variant="outlined" />
                    </div>
                    <div className='col-md-6 mb-3'>
                        <TextField id="outlined-basic" name="password" value={obj.password} onChange={onChange} label="Password" variant="outlined" />
                    </div>
                    <div className='col-md-6 mb-3'>
                        <TextField id="outlined-basic" name="confirm_password" value={obj.confirm_password} onChange={onChange} label="Confirm Password" variant="outlined" />
                    </div>
                    <div className='col-md-6 mb-3'>
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">Please select a company</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={obj.companyId}
                                name="companyId"
                                label="Company"
                                onChange={onChange}
                            >
                                {companies.map(item => <MenuItem value={item._id}>{item.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-6 mb-3'>
                        <Button variant="contained" style={{ color: "white !important" }} onClick={submitDetails}>Submit</Button>
                    </div>
                </div> : <ImportData companies={companies} />}

            </BackPaper>
        </>
    )
}

export default AddUser